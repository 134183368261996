<template>
    <div class='information'>
        <div class="dietBgaimg">
            <img class="img" :src="dietTyerapyUrl" alt="">
        </div>
        <div class="inform">
            <div class="title">
                <div class="text">填写信息</div>
            </div>
            <van-form validate-first @submit="onSubmit">
                <van-field
                    v-model="form.name"
                    name="name"
                    label="姓名"
                    placeholder="请输入您的姓名"
                    :rules="[{ required: true, message: '请输入您的姓名' }]"
                />
                <van-field name="sex" label="性别" :rules="[{ required: true, message: '请选择您的性别' }]">
                    <template #input>
                        <van-radio-group v-model="form.gender" direction="horizontal" >
                            <van-radio name="男" :class="form.gender == '男' ? 'checked' : ''">男士</van-radio>
                            <van-radio name="女" :class="form.gender == '女' ? 'checked' : ''">女士</van-radio>
                        </van-radio-group>
                    </template>
                </van-field>
                <van-field
                    v-model="form.age"
                    name="age"
                    label="年龄"
                    placeholder="请输入您的年龄"
                    :rules="[{ required: true, message: '请输入您的年龄' },{ validator, message: '请输入正确您的年龄' }]"
                />
                <div class="btn">
                    <van-button round type="info" native-type="submit">提交</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>

export default {
  components: {

  },
  data () {
    return {
      form: {},
      dietTyerapyUrl: `${this.$imgDomain}/www/HealthingCore/dietTyerapy1.png`
    }
  },
  props: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    onSubmit (data) {
      console.log(data, '7777777777777')
      this.$router.push({ path: '/healthTest', query: { userData: JSON.stringify(data) } })
    },
    // 年龄校验
    validator (val) {
      return /^(?:[1-9][0-9]?|1[01][0-9]|120)$/.test(val)
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.information {
    height: 100vh;
    background: linear-gradient(0deg,#73ddd0 0%, #52cfe7 100%);
    padding: 0 15px 50px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .dietBgaimg {
        transform: translateY(60px);
    }
    .inform {
        background: #fff;
        border-radius: 15px;
        z-index: 999;
        .title {
            display: flex;
            justify-content: center;
            padding: 30px 0 20px;
            .text {
                color: #333333;
                font-weight: 700;
                font-size: 20px;
                position: relative;
            }
            .text::before,
            .text::after{
                content: "";
                position: absolute;
                top: 10px;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: #57D1E6;
            }
            .text::before {
                left: -20px;
            }
            .text::after {
                right: -20px;
            }
        }
        /deep/ .van-cell{
            padding: 14px 16px;
        }
        /deep/ .van-field__label {
            color: #999999;
            font-size: 18px;
        }
        /deep/ .van-field__control {
            color: #999999;
            font-size: 18px;
            background-color: #fff;
        }
        /deep/ .van-radio {
            background: #cccccc;
            width: 50px;
            display: flex;
            justify-content: center;
            border-radius: 20px;
            font-size: 12px;
            .van-icon {
                display: none;
            }
            .van-radio__label {
                color: #fff;
                margin: 0;
            }
        }
        .checked {
            background: #6dd6da;
        }
        .btn {
            text-align: center;
            padding: 40px 0;
            /deep/ .van-button {
                background: linear-gradient(-58deg,#6dd6da 0%, #52cfe6 100%);
                border: none;
                width: 200px;
                .van-button__text {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

    }
}
</style>
